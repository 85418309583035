import React from "react";

const ProgramsDetail = () => {
  return (
    <>
      <div className="tp-price-table pt-130">
        <div className="container">
          <div className="row">
            <a id="littletigers"></a>
            <div className="col-xl-12 col-md-20">
              <div className="price-table__item pt-50 pb-40 pl-40 pr-40 price-border mb-30">
                <div className="price-table__top d-flex justify-content-between mb-50">
                  <span>Program1</span>
                  <span>
                    <i className="pe-7s-cup"></i>
                  </span>
                </div>
                <div className="price-table__content">
                  <div className="row">
                    <div className="col-xl-6 col-md-12">
                      <h2 className="price-table__price mb-15">
                        Little tigers
                      </h2>
                      <h3 className="price-table__title mb-30">Age 4-5</h3>
                      <div className="price-table__list mb-30 ">
                        <ul>
                          <li>
                            <span>
                              <i className="fal fa-check"></i>
                            </span>
                            Exclusively for the 4 and 5 year old students
                          </li>
                          <br />
                          <li>
                            <span>
                              <i className="fal fa-check"></i>
                            </span>
                            Patient, enthusiastic instructors make learning fun
                          </li>
                          <br />
                          <li>
                            <span>
                              <i className="fal fa-check"></i>
                            </span>
                            Fosters self-confidence and socializing skills
                          </li>
                          <br />
                          <li>
                            <span>
                              <i className="fal fa-check"></i>
                            </span>
                            Builds coordination &amp; balance
                          </li>
                          <br />
                          <li>
                            <span>
                              <i className="fal fa-check"></i>
                            </span>
                            Start healthy habits early
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="price-table__list mb-30 col-xl-6 col-md-12">
                      <img src="/assets/img/programs/programs-littletiger.png" />
                    </div>
                  </div>

                  <div
                    className="price-center price-table__list mb-55"
                    // style={{ textAlign: "center" }}
                  >
                    <p>
                      If you are looking for a fun, new activity for your 4 or
                      5-year-old child, consider Deschutes Jung’s Taekwondo
                      Little Tigers martial arts classes for preschoolers and
                      kindergarteners.
                    </p>
                    <p>
                      Each thirty-minute session includes exercises to develop
                      balance, coordination, and gross motor skills. Little
                      tigers run, jump, kick, and have a great time while
                      improving their athleticism and the enjoyment of being
                      physically active.{" "}
                    </p>
                    <p>
                      They also learn basic social skills as they practice in a
                      group, cooperate with a partner, and learn to focus on a
                      teacher and follow verbal instructions. This is an
                      excellent way to prepare your child for greater success at
                      school and other team sports.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12 col-md-20">
              <div className="price-table__item pt-50 pb-40 pl-40 pr-40 price-border mb-30">
                <div className="price-table__top d-flex justify-content-between mb-50">
                  <span>Program2</span>
                  <span>
                    <i className="pe-7s-diamond"></i>
                  </span>
                </div>
                <div className="price-table__content">
                  <div className="row">
                    <div className="col-xl-6 col-md-12">
                      <h2 className="price-table__price mb-15">
                        Children class
                      </h2>
                      <h3 className="price-table__title mb-30">Age 6-12</h3>
                      <div className="price-table__list mb-30">
                        <ul>
                          <li>
                            <span>
                              <i className="fal fa-check"></i>
                            </span>
                            High energy, fun classes
                          </li>
                          <br />
                          <li>
                            <span>
                              <i className="fal fa-check"></i>
                            </span>
                            Students learn respect, self-discipline, and
                            perseverance
                          </li>
                          <br />
                          <li>
                            <span>
                              <i className="fal fa-check"></i>
                            </span>
                            Greater focus leads to academic success
                          </li>
                          <br />
                          <li>
                            <span>
                              <i className="fal fa-check"></i>
                            </span>
                            Learn the power of goal setting
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="price-table__list mb-30 col-xl-6 col-md-12">
                      <img src="/assets/img/programs/programs-childrenclass.png" />
                    </div>
                  </div>

                  <div className="price-center price-table__list mb-55">
                    <p>We teach kids success skills for life!</p>
                    <p>
                      Our Children’s program has been designed to help your
                      child to achieve greater success in all areas of their
                      life. We accomplish this by providing training designed to
                      enhance your child’s physical, mental and social
                      development.
                    </p>
                    <p>
                      In a typical forty-minute class, students will participate
                      in a series of drills and skills that work on their
                      strength, flexibility, and endurance. They will also
                      increase their coordination, reflexes, and athletic
                      skills.
                    </p>
                    <p>
                      Best of all, they will love the excitement of learning Tae
                      Kwon Do kicks, forms, and self-defense techniques. Your
                      child will look forward to each class and to spending time
                      with their friendly classmates and instructors. Our
                      instructors are experts at motivating children and know
                      how to motivate them to give their best effort while still
                      keeping classes fun.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-12 col-md-20">
              <div className="price-table__item pt-50 pb-40 pl-40 pr-40 price-border mb-30">
                <div className="price-table__top d-flex justify-content-between mb-50">
                  <span>Program3</span>
                  <span>
                    <i className="pe-7s-star"></i>
                  </span>
                </div>
                <div className="price-table__content">
                  <div className="row">
                    <div className="col-xl-6 col-md-12">
                      <h4 className="price-table__price mb-15">
                        Teen &amp; Adult
                      </h4>
                      <h3 className="price-table__title mb-30">
                        Age 13 &amp; Up
                      </h3>
                      <div className="price-table__list mb-30">
                        <ul>
                          <li>
                            <span>
                              <i className="fal fa-check"></i>
                            </span>
                            Total body workout
                          </li>
                          <br />
                          <li>
                            <span>
                              <i className="fal fa-check"></i>
                            </span>
                            Practical self-defense skills
                          </li>
                          <br />
                          <li>
                            <span>
                              <i className="fal fa-check"></i>
                            </span>
                            Taught with understanding for your age and fitness
                            level
                          </li>
                          <br />
                          <li>
                            <span>
                              <i className="fal fa-check"></i>
                            </span>
                            Flexible Class schedule
                          </li>
                          <br />
                          <li>
                            <span>
                              <i className="fal fa-check"></i>
                            </span>
                            Great way to relieve stress
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="price-table__list mb-30 col-xl-6 col-md-12">
                      <img src="/assets/img/programs/programs-adult.jpeg" />
                    </div>
                  </div>

                  <div className="price-center price-table__list mb-55">
                    <p>
                      The majority of our adult students started with no
                      previous martial arts experience. They came to our school
                      for a variety of reasons ranging from exercise and stress
                      relief to learning self-defense or gaining
                      self-confidence.
                    </p>
                    <p>
                      Tae Kwon Do makes an excellent workout, with each class
                      including a thorough stretching routine, bursts of cardio
                      intense drills, plus body weight and core focused strength
                      exercises.
                    </p>
                    <p>
                      For over twenty-five years we have helped thousands of men
                      and woman to achieve their
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProgramsDetail;
