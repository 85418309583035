import React from "react";

function TableComponent() {
  // 5행 7열 데이터를 생성합니다. 실제 사용 시에는 이 부분을 적절한 데이터로 채우세요.
  const rows = 5;
  const cols = 7;
  const tableData = Array.from({ length: rows }, () =>
    Array.from({ length: cols }, () => "")
  );

  return (
    <div>
      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <tbody>
          {tableData.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <td
                  key={cellIndex}
                  style={{
                    border: "1px solid black",
                    padding: "10px",
                    textAlign: "center",
                  }}
                >
                  {/* 셀 데이터를 여기에 표시 */}
                  {cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default TableComponent;
