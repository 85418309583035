const menu_data = [
  {
    id: 1,
    title: "Home",
    link: "/",
  },
  {
    id: 2,
    title: "About Us",
    link: "/about",
  },
  {
    id: 3,
    // hasDropdown: true,
    title: "Programs",
    link: "/programs",
    // submenus: [
    //   { title: "Team", link: "/team" },
    //   { title: "Team Details", link: "/team-details" },
    //   { title: "Service", link: "/service" },
    //   { title: "Schedule", link: "/schedule" },
    //   { title: "Price", link: "/price" },
    //   { title: "Contact", link: "/contact" },
    //   { title: "Faq", link: "/faq" },
    // ],
  },
  {
    id: 4,
    // hasDropdown: true,
    title: "Schedule",
    link: "/schedule",
    // submenus: [
    //   { title: "Portfolio", link: "/portfolio" },
    //   { title: "Portfolio Details", link: "/portfolio-details" },
    // ],
  },
  {
    id: 5,
    // hasDropdown: true,
    title: "Contact",
    link: "/contact",
    // submenus: [
    //   { title: "Blog", link: "/blog" },
    //   { title: "Blog Details", link: "/blog-details" },
    // ],
  },
];

export default menu_data;
