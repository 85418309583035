import React from "react";
import { Link } from "react-router-dom";

const FooterThree = () => {
  return (
    <footer>
      <div className="tp-footer__area black-bg">
        <div className="tp-footer">
          {/* <!-- main-footer start  --> */}
          <div className="tp-footer__main">
            <div className="container">
              <div className="tp-footer-border pt-100 pb-70">
                <div className="row">
                  <div className="col-lg-3 col-md-6">
                    <div className="tp-footer__widget tp-footer__2 pb-30">
                      <div className="footer-logo mb-30">
                        <Link to="/">
                          <img
                            src="/assets/img/logo/logo_djt_footer.png"
                            alt=""
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="tp-footer__widget tp-footer__2 pb-30"></div>
                  </div>
                  <div className="col-lg-3 col-md-6 ">
                    <div className="tp-footer__widget tp-footer__2  pb-30">
                      <h3 className="tp-footer__widget-title text-white">
                        Quick Links
                      </h3>
                      <ul>
                        <li>
                          <Link to="/about">About Us</Link>
                        </li>
                        <li>
                          <Link to="/programs">Programs</Link>
                        </li>
                        <li>
                          <Link to="/schedule">Schedule</Link>
                        </li>
                        <li>
                          <Link to="/contact">Contact</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="tp-footer__widget tp-footer__2  pb-30">
                      <h3 className="tp-footer__widget-title text-white">
                        Quick Links
                      </h3>
                      <ul>
                        <li>
                          <a href="https://maps.app.goo.gl/NCKkRuvYv7eADAjM8">
                            1900 NE 3rd St #105, Bend, OR 97701
                          </a>
                        </li>
                        <li>
                          <a href="tel:+1(971)501-8114">(971) 501 - 8114</a>
                        </li>
                        <li>
                          <a href="mailto:deschutes.tkd@gmail.com">
                            deschutes.tkd@gmail.com
                          </a>
                        </li>
                        <li>
                          <span>
                            Monday ~ Friday
                            <br />
                            2PM ~ 8PM
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- main footer end  --> */}
        </div>
      </div>

      {/* <!-- footer copy right --> */}
      <div className="top-footer-copyright pt-30 pb-30 black-bg">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="tp-copyrigh-text text-center text-white">
                <span>
                  © {new Date().getFullYear()} Deschutes Jung's Taekwondo . All
                  Rights Reserved..
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- footer copyright end  --> */}
    </footer>
  );
};

export default FooterThree;
