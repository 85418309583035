import React from "react";
import { Route, Routes } from "react-router-dom";
// internal
import BackToTop from "../components/BackToTop";
import AnimateMouse from "../components/common/AnimateMouse";
import ContextProvider from "../components/context/ContextProvider";
import Home from "../components/Home/Home";
import HomeThree from "../components/HomeThree/HomeThree";
import HomeTwo from "../components/HomeTwo/HomeTwo";
import About from "../components/Pages/About/About";
import Blog from "../components/Pages/Blog/Blog";
import BlogDetails from "../components/Pages/BlogDetails/BlogDetails";
import ContactUs from "../components/Pages/ContactUs/ContactUs";
import Portfolio from "../components/Pages/Portfolio/Portfolio";
import PortfolioDetails from "../components/Pages/PortfolioDetails/PortfolioDetails";
import Programs from "../components/Pages/Programs/Programs";
import ServiceDetails from "../components/Pages/ServiceDetails/ServiceDetails";
import TeamDetails from "../components/Pages/TeamDetails/TeamDetails";
import TeamPage from "../components/Pages/TeamPage/TeamPage";
import Contact from "../components/common/Contact/Contact";
const AppNavigation = () => {
  return (
    <ContextProvider>
      {/* <AnimateMouse /> */}
      <Routes>
        <Route path="/" element={<HomeThree />} />
        <Route path="/home-2" element={<HomeTwo />} />
        <Route path="/home-3" element={<HomeThree />} />
        <Route path="/about" element={<About />} />
        <Route path="/team" element={<TeamPage />} />
        <Route path="/team-details" element={<TeamDetails />} />
        <Route path="/schedule" element={<ServiceDetails />} />
        <Route path="/programs" element={<Programs />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/portfolio-details" element={<PortfolioDetails />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog-details" element={<BlogDetails />} />
        <Route path="/contactd" element={<Contact/>}/>
      </Routes>
      {/* <ContactUs /> */}
      <BackToTop />
    </ContextProvider>
  );
};

export default AppNavigation;
