import React from "react";
import { useState } from "react";
import axios from "axios";

const Contact = () => {

  const [formType, setFormType] = useState("inquiry"); // 라디오 버튼 상태
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);

  // 입력 값 변경 핸들러
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // 라디오 버튼 변경 핸들러
  const handleTypeChange = (e) => {
    const newType = e.target.value;
    setFormType(newType);

    // "free trial" 선택 시 자동 입력
    if (newType === "2 weeks free trial") {
      setFormData({
        ...formData,
        name: "Free",
        message: "free trial inquiry",
      });
    }
  };

  // "Free Trial" 버튼 클릭 핸들러
  const handleFreeTrialClick = () => {
    setFormData({
      ...formData,
      message: "Try free 2 weeks trail lesson",
    });
  };

  // 폼 제출 핸들러
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    // 폼 데이터를 사용하여 메일 보내기 로직 구현 (여기서는 예시로만 처리)
    console.log("Form Data:", formData);
    axios({
      method: "post",
      url: "/api/contact",
      //frontend 폴더 연동시에는 /contact 로 변경.
      // proxy: "http://localhost:8080",

      data: {
        name: formData.name,
        email: formData.email,
        message: formData.message,
      },
    }).then((res) => {
      if (res.data === true) {
        alert(
          "Your inquiry has been successfully submitted. We'll get back to you soon!"
        );
      } else {
        alert("The email could not be sent properly. Please try again.");
      }
      setLoading(false);
      window.location.replace(`/contact`);
    });
  };


  return (
    <>
      <div className="row">
            <div className="col-12">
              <div className="tp-ct-form white-bg pl-110 pr-110 pt-80 pb-130">
                <h4 className="tp-contact-form-title">
                  How can we help you today?
                </h4>
                <div style={{ marginBottom: "20px" }}>
                  <button
                    type="submit"
                    className="tp-btn-border"
                    onClick={handleFreeTrialClick}
                  >
                    2 weeks free trial{" "}
                  </button>
                </div>
                
                <form id="contact-form" onSubmit={handleSubmit}>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Enter your name*"
                    required
                  />

                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Your email here"
                    required
                  />

                  <textarea
                    name="message"
                    placeholder="Enter your Massage*"
                    value={formData.message}
                    onChange={handleChange}
                  ></textarea>
                  <div className="text-center">
                    {!loading && (
                      <button
                        type="submit"
                        className="tp-btn-border"
                        disabled={loading}
                      >
                        Send Massage{" "}
                        <span>
                          <svg
                            width="22"
                            height="8"
                            viewBox="0 0 22 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                          <svg
                            width="22"
                            height="8"
                            viewBox="0 0 22 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </span>
                      </button>
                    )}
                    <div className="loader-container">
                      {loading && <div className="load-pg"></div>}
                    </div>
                  </div>
                </form>
                <p className="ajax-response mt-20 text-center"></p>
              </div>
            </div>
          </div>
    </>
  );
};

export default Contact;
