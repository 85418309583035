import { useRef, useState } from "react";
import React from "react";
import Slider from "react-slick";
import ImagePopup from "../../common/Modals/ImagePopup";

// slider_img_data
const slider_img_data = [
  {
    index: 0,
    img: "/assets/img/about/slider-2.png",
  },
  {
    index: 1,
    img: "/assets/img/about/slider-3.png",
  },
  {
    index: 2,
    img: "/assets/img/about/slider-4.png",
  },
  {
    index: 3,
    img: "/assets/img/about/slider-5.png",
  },
  {
    index: 4,
    img: "/assets/img/about/slider-6.png",
  },
  {
    index: 5,
    img: "/assets/img/about/slider-7.png",
  },
  {
    index: 6,
    img: "/assets/img/about/slider-8.png",
  },
  {
    index: 7,
    img: "/assets/img/about/slider-9.png",
  },
];
const settings = {
  autoplay: true,
  autoplaySpeed: 4000,
  dots: false,
  fade: false,
  arrows: false,
};

const AboutCompany = () => {
  const sliderRef = useRef(null);
  // photoIndex
  const [photoIndex, setPhotoIndex] = useState(null);
  // image open state
  const [isOpen, setIsOpen] = useState(false);
  // handleImagePopup
  const handleImagePopup = (i) => {
    setPhotoIndex(i);
    setIsOpen(true);
  };
  //  images
  const images = [...new Set(slider_img_data.map((item) => item.img))];

  return (
    <>
      <div className="about-details-page pt-140 pb-110">
        <div className="container">
          {/* row1 */}
          <div className="row" style={{ marginBottom: "50px" }}>
            <div className="col-lg-6 col-12">
              <div className="about-details-info pr-65">
                <div className="tp-section-wrapper">
                  {/* <span className="tp-section-subtitle mb-25">
                    About our Company
                  </span> */}
                  <h2 className="tp-pt-size mb-40">
                    Deschutes Jung’s Taekwondo
                  </h2>
                </div>
                <div className="about-tb-content">
                  <nav>
                    <div className="nav mb-30" id="nav-tab" role="tablist">
                      {/* <button
                        className="nav-links active"
                        id="mission"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-mission"
                        type="button"
                        role="tab"
                        aria-controls="nav-mission"
                        aria-selected="true"
                      >
                        Our Mission
                      </button> */}

                      {/* <button
                        className="nav-links"
                        id="nav-vission-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-vission"
                        type="button"
                        role="tab"
                        aria-controls="nav-vission"
                        aria-selected="false"
                      >
                        Our Vission
                      </button>
                      <button
                        className="nav-links"
                        id="nav-value-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-value"
                        type="button"
                        role="tab"
                        aria-controls="nav-value"
                        aria-selected="false"
                      >
                        Our Value
                      </button> */}
                    </div>
                  </nav>
                  <div className="tab-content" id="nav-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="nav-mission"
                      role="tabpanel"
                      aria-labelledby="nav-mission"
                    >
                      <p>
                        Welcome to Deschutes Jung’s Taekwondo! We offer expert
                        training in traditional Korean martial arts, focusing on
                        discipline, respect, and physical fitness. Join us to
                        enhance your skills and achieve your goals!
                      </p>
                    </div>
                    {/* <div
                      className="tab-pane fade"
                      id="nav-vission"
                      role="tabpanel"
                      aria-labelledby="nav-vission-tab"
                    >
                      <p>
                        Phasellus volutpat ac tincidunt vitae semper quis lectus
                        nulla at. Libero nisl id venenatis a condimentum. Libero
                        justo laoreet sit amet. Nisl nisi scelerisque eu
                        ultrices vitae. Dolor sit amet consectetur adipiscing
                        elit pellentesque. Phasellus egestas tellus rutrum
                      </p>

                      <p>
                        ullamcorper morbi tincidunt ornare massa potenti nullam
                        ac tortor vitae purus. faucibus ornare suspendisse.
                        Donec enim diam vulputate ut pharetra sit amet aliquam
                        id. Eu mi bibendum neque egestas congue purus non enim
                        praesent.Donec enim diam vulputate ut pharetra sit amet
                        aliquam id. Eu mi bibendum neque
                      </p>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="nav-value"
                      role="tabpanel"
                      aria-labelledby="nav-value-tab"
                    >
                      <p>
                        Neque volutpat ac tincidunt vitae semper quis lectus
                        nulla at. Nibh nisl id venenatis a condimentum. Libero
                        justo laoreet sit amet. Nisl nisi scelerisque eu
                        ultrices vitae. Dolor sit amet consectetur adipiscing
                        elit pellentesque. Phasellus egestas tellus rutrum
                      </p>

                      <p>
                        ullamcorper morbi tincidunt ornare massa potenti nullam
                        ac tortor vitae purus. faucibus ornare suspendisse.
                        Donec enim diam vulputate ut pharetra sit amet aliquam
                        id. Eu mi bibendum neque egestas congue purus non enim
                        praesent.Donec enim diam vulputate ut pharetra sit amet
                        aliquam id. Eu mi bibendum neque
                      </p>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-12 about-img">
              <div className="tp-about-img-box p-relative d-inline-block mb-30">
                <div className="about-page-img">
                  <img src="assets/img/about/about-djt.jpg" alt="" />
                </div>
                {/* <div className="dots-img">
                  <img src="assets/img/about/dot.jpg" alt="" />
                </div>
                <div className="about-info-box d-flex flex-column justify-content-center text-center">
                  <h3 className="box-title">24</h3>
                  <h4 className="box-subtitle">
                    Year of <br />
                    Experience
                  </h4>
                </div> */}
              </div>
            </div>
          </div>
          {/* row2 */}
          <div className="row" style={{ marginBottom: "50px" }}>
            <div className="col-lg-6 col-12">
              <div className="about-details-info pr-65">
                <div className="tp-section-wrapper">
                  {/* <span className="tp-section-subtitle mb-25">
                    About our Company
                  </span> */}
                  <h2 className="tp-pt-size mb-40">Who is Master Jung</h2>
                </div>
                <div className="about-tb-content">
                  <nav>
                    <div className="nav mb-30" id="nav-tab" role="tablist">
                      {/* <button
                        className="nav-links active"
                        id="mission"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-mission"
                        type="button"
                        role="tab"
                        aria-controls="nav-mission"
                        aria-selected="true"
                      >
                        Our Mission
                      </button> */}

                      {/* <button
                        className="nav-links"
                        id="nav-vission-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-vission"
                        type="button"
                        role="tab"
                        aria-controls="nav-vission"
                        aria-selected="false"
                      >
                        Our Vission
                      </button>
                      <button
                        className="nav-links"
                        id="nav-value-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-value"
                        type="button"
                        role="tab"
                        aria-controls="nav-value"
                        aria-selected="false"
                      >
                        Our Value
                      </button> */}
                    </div>
                  </nav>
                  <div className="tab-content" id="nav-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="nav-mission"
                      role="tabpanel"
                      aria-labelledby="nav-mission"
                    >
                      <p>*4th degree black belt certified from Kukkiwon</p>
                      <p>*1st degree black belt Judo</p>
                      <p>*1st degree black belt Hapkido</p>
                      <p>*Certified Child Physical Education</p>
                      <p>
                        *state,national,international, college Division A Medal
                        list (2003~2012)
                      </p>
                      <p>
                        *Majored Sports Management, Taekwondo In Dankook
                        University(2010~2015)
                      </p>
                      <p>
                        *Served Republic of Korea Marine corps(2011.3~2012.12)
                      </p>
                      <p>
                        *Volunteered world taekwondo peace corps
                        foundation(Served at Brunei 2013)
                      </p>
                      <p>*Licensed professional boxer(2014)</p>
                      <p>
                        *Head instructor of LMMA located wilsonville,
                        OR(2015~2023)
                      </p>
                    </div>
                    {/* <div
                      className="tab-pane fade"
                      id="nav-vission"
                      role="tabpanel"
                      aria-labelledby="nav-vission-tab"
                    >
                      <p>
                        Phasellus volutpat ac tincidunt vitae semper quis lectus
                        nulla at. Libero nisl id venenatis a condimentum. Libero
                        justo laoreet sit amet. Nisl nisi scelerisque eu
                        ultrices vitae. Dolor sit amet consectetur adipiscing
                        elit pellentesque. Phasellus egestas tellus rutrum
                      </p>

                      <p>
                        ullamcorper morbi tincidunt ornare massa potenti nullam
                        ac tortor vitae purus. faucibus ornare suspendisse.
                        Donec enim diam vulputate ut pharetra sit amet aliquam
                        id. Eu mi bibendum neque egestas congue purus non enim
                        praesent.Donec enim diam vulputate ut pharetra sit amet
                        aliquam id. Eu mi bibendum neque
                      </p>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="nav-value"
                      role="tabpanel"
                      aria-labelledby="nav-value-tab"
                    >
                      <p>
                        Neque volutpat ac tincidunt vitae semper quis lectus
                        nulla at. Nibh nisl id venenatis a condimentum. Libero
                        justo laoreet sit amet. Nisl nisi scelerisque eu
                        ultrices vitae. Dolor sit amet consectetur adipiscing
                        elit pellentesque. Phasellus egestas tellus rutrum
                      </p>

                      <p>
                        ullamcorper morbi tincidunt ornare massa potenti nullam
                        ac tortor vitae purus. faucibus ornare suspendisse.
                        Donec enim diam vulputate ut pharetra sit amet aliquam
                        id. Eu mi bibendum neque egestas congue purus non enim
                        praesent.Donec enim diam vulputate ut pharetra sit amet
                        aliquam id. Eu mi bibendum neque
                      </p>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-12 about-img">
              <div className="tp-about-img-box p-relative d-inline-block mb-30">
                <div className="about-page-img">
                  <img src="assets/img/about/about-jung.jpg" alt="" />
                </div>
                {/* <div className="dots-img">
                  <img src="assets/img/about/dot.jpg" alt="" />
                </div>
                <div className="about-info-box d-flex flex-column justify-content-center text-center">
                  <h3 className="box-title">24</h3>
                  <h4 className="box-subtitle">
                    Year of <br />
                    Experience
                  </h4>
                </div> */}
              </div>
            </div>
            <div
              className="col-lg-12 mt-20 text-center postbox__thumb postbox__slider swiper-container w-img p-relative"
              style={{ paddingLeft: "30px", paddingRight: "30px" }}
            >
              <Slider ref={sliderRef} {...settings}>
                {slider_img_data.map((item) => (
                  <div key={item.index} className="postbox__slider-item">
                    <button
                      onClick={() => handleImagePopup(item.index)}
                      // className="project-gallery-image"
                    >
                      <img src={item.img} alt="" />
                    </button>
                  </div>
                ))}
                {/* <div className="postbox__slider-item">
                  <button
                    // onClick={() => handleImagePopup(item.index)}
                    className="project-gallery-image"
                  >
                    <img src="/assets/img/about/about-slider-1.jpg" alt="" />
                  </button>
                </div> */}
              </Slider>
              <div className="postbox-nav">
                <button
                  onClick={() => sliderRef.current?.slickNext()}
                  className="postbox-slider-button-next postbox__slider__btn"
                >
                  <i className="fal fa-angle-right"></i>
                </button>
                <button
                  onClick={() => sliderRef.current?.slickPrev()}
                  className="postbox-slider-button-prev postbox__slider__btn"
                >
                  <i className="fal fa-angle-left"></i>
                </button>
              </div>
            </div>
          </div>
          {/* row3 */}
        </div>
      </div>
      {/* image light box start */}
      {isOpen && (
        <ImagePopup
          images={images}
          setIsOpen={setIsOpen}
          photoIndex={photoIndex}
          setPhotoIndex={setPhotoIndex}
        />
      )}
      {/* image light box end */}
    </>
  );
};

export default AboutCompany;
