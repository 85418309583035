import { useState } from "react";
import ImagePopup from "../common/Modals/ImagePopup";
import { Link } from "react-router-dom";

// portfolio_data
const portfolio_data = [
  {
    index: 0,
    // link: "/programs/#littletigers",
    bgimg: "/assets/img/programs/programs-littletiger3-home.png",
    // tag: "Business",
    category: "little tigers",
  },
  {
    index: 1,
    // link: "/programs/childrenclass",
    bgimg: "/assets/img/programs/programs-childrenclass3-home.png",
    // tag: "Data Analysis",
    category: "children class",
  },
  {
    index: 2,
    // link: "/programs/adult",
    bgimg: "/assets/img/programs/programs-adult3-home.png",
    // tag: "Product Design",
    category: "teen & adult",
  },
];
// categories
const categories = [
  "All",
  ...new Set(portfolio_data.map((item) => item.category)),
];

const PortfolioThree = () => {
  const [isActive, setIsActive] = useState("All");
  const [portfolioItems, setPortfolioItems] = useState(portfolio_data);

  // photoIndex
  const [photoIndex, setPhotoIndex] = useState(null);
  // image open state
  const [isOpen, setIsOpen] = useState(false);
  // handleImagePopup
  const handleImagePopup = (i) => {
    setPhotoIndex(i);
    setIsOpen(true);
  };
  //  images
  const images = [...new Set(portfolio_data.map((item) => item.img))];

  // handle portfolio data
  const handlePortfolioData = (category) => {
    setIsActive(category);
    if (category === "All") {
      setPortfolioItems(portfolio_data);
    } else {
      setPortfolioItems(
        portfolio_data.filter((item) => item.category === category)
      );
    }
  };
  return (
    <>
      {/* <!-- portfolio area start  --> */}
      <div className="tp-portfolio-area">
        <div className="container p-0">
          <div className="tp-portfolio-header mb-70">
            <div className="row align-items-center g-0">
              <div className="col-lg-5 col-12">
                <div className="tp-portfolio-info">
                  {/* <div className="tp-portfolio-subtitle mb-15">
                    <span>Programs</span>
                  </div> */}
                  <h3 className="tp-portfolio-title">Programs</h3>
                </div>
              </div>
              <div className="col-lg-7 col-12">
                <div className="tp-pf-btn text-lg-end mt-35">
                  {categories.map((cate, i) => (
                    <button
                      key={i}
                      onClick={() => handlePortfolioData(cate)}
                      className={`${isActive === cate ? "active" : ""}`}
                    >
                      {cate.split("-").join(" ").toUpperCase()}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="row tp-portfolio-active text-center">
            {portfolioItems
              .filter((item) => item.category === "little tigers")
              .map((item) => (
                <div
                  key={item.index}
                  className="col-lg-4 tp-portfolio-item w-img"
                >
                  <div className="fix">
                    <button className="project-gallery-image">
                      <a href="/programs">
                        <img src={item.bgimg} alt="" />
                      </a>
                    </button>
                  </div>
                  {/* <div className="portfolio-tag">
                    <a href="#">
                      <span>Business</span>
                    </a>
                  </div> */}
                </div>
              ))}
            {portfolioItems
              .filter((item) => item.category != "little tigers")
              .map((item) => (
                <div
                  key={item.index}
                  className="col-lg-4 tp-portfolio-item w-img"
                >
                  <div>
                    <button className="project-gallery-image">
                      <a href="/programs">
                        <img src={item.bgimg} alt="" />
                      </a>
                    </button>
                  </div>
                  {/* <div className="portfolio-tag">
                    <a href="#">
                      <span>Business</span>
                    </a>
                  </div> */}
                </div>
              ))}
          </div>
          <div className="text-center mb-reset mt-70 mb-140">
            <Link to="/programs" className="tp-btn-border">
              Explore More
              <span>
                <svg
                  width="22"
                  height="8"
                  viewBox="0 0 22 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                    fill="currentColor"
                  ></path>
                </svg>
                <svg
                  width="22"
                  height="8"
                  viewBox="0 0 22 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </span>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default PortfolioThree;
