import React from "react";
import Brands from "../../common/Brands/Brands";
import Contact from "../../common/Contact/Contact";
import FooterThree from "../../common/Footer/FooterThree";
import Menu from "../../common/Menu/Menu";
import Testimonial from "../../common/Testimonial/Testimonial";
import ProgramsBanner from "./ProgramsBanner";
import ProgramsDetail from "./ProgramsDetail";

const Programs = () => {
  return (
    <>
      <Menu header_style_2={true} />
      <ProgramsBanner />
      <ProgramsDetail />
      {/* <Testimonial /> */}
      {/* <Brands style_2={true} /> */}
      {/* <Contact /> */}
      <FooterThree />
    </>
  );
};

export default Programs;
